import react,{ useState } from "react"
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Bottomstrip from "./Bottomstrip";
import './custome.css'
import Axios from "axios";
import thankyou from '../thankyou.png'
import MuiAlert from "@material-ui/lab/Alert";



function Alert(props) {
    return <MuiAlert elevation={6}
        variant="filled" {...props} />;
}
export default function Customerlogin(){

    let customerNumber = '';
    const [isValidCust, setIsValidCust] = useState(true);
    const [Custmsisdn, setCustmsisdn] = useState('')
    

   // const [customermsisdn,setcustomermsisdn] = useState()
    const [otp,Setotp] = useState()
    const[otpSent, isOtpSent] = useState();
    const[otpbox,showotpbox] = useState(false)
    const[showUpload,setshowUpload] = useState(false)
    const[otpSuccess, isOtpSuccess] = useState();
    const[otpFailed, isOtpFailed] = useState();
    const msisdnSeries = [
        '527',
        '528',
        '529',
        '589',
        '586',
        '588',
        '595',
        '596',
        '552',
        '702',
        '731'

    ];



    setTimeout(
        () => setIsValidCust(true),
        3000
    );

    function checkforverification(){
        const req = Axios.get('https://ekyc.chili.mu:9443/api/check/verified/msisdn/230'+Custmsisdn,
        {
            headers: { 'Content-Type': 'application/json' }

        }).then((res)=>{
            console.log(res.data.status)
            if(res.data.status=='SUCCESSFUL'){
            sendOTP()
            }
        }).catch((error)=>{
            console.log(error.response.data.data[0].token)
            let tkn = error.response.data.data[0].token
            alert(`This MSISDN is already Activated with Token Number ${tkn}`)
            //send SMS here
            // sendSMS(customerNumber)
            console.log(error.response)
        })
    }

     function sendSMS(msisdn){
       

        //let txt = `Dear Customer, Congratulations! Welcome to the family of CHILI. Your reference for eKYC Corelation ID is- ${this.state.token} for ${this.state.customernumber} Enjoy the CHiLi services.`
        let txt = "Dear CHiLi Customer Your Number is already Verified";
       
        const req = Axios.get("https://ekyc.chili.mu:9443/ekyc/v1/sms?msisdn="+"230"+msisdn+"&from=2306668&text="+txt+"&configId=2&locale=en",
            {
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }).then((res) => {
            console.log("result is ",res.data.status)
           }).catch((error)=>{
                        console.log(error);
                    });  
                   
    }

    function sendOTP() {
        
        if(Custmsisdn!='' && verifyMsisdn(Custmsisdn)){
        const req = Axios.get("https://ekyc.chili.mu:9443/otp/sendOtpAgent/" + "230" + customerNumber + "/en",
            {
                headers: { 'Content-Type': 'application/json' }
            }).then((res) => {

                isOtpSent(true);
                showotpbox(true);

            }).catch((error) => {
                console.log(error);
                showotpbox(false);
                isOtpSent(false)
            });
        }
    }


    function verifyMsisdn(msisdn) {
        if (msisdn.length == 8) {

            for (var i = 0; i < msisdnSeries.length; i++) {
                console.log(i, msisdn);
                if (msisdn.startsWith(msisdnSeries[i])) {
                    setIsValidCust(true);
                    customerNumber = msisdn;
                    setCustmsisdn(msisdn);
                    return true;
                } else {
                    console.log('Invalid');
                    setIsValidCust(false);
                    

                }
            }
            setCustmsisdn(msisdn);
            return false;
        }

    }
    
    function verifyOTP() {
        isOtpSent()
        let data = { Custmsisdn, otp }
        const req = Axios.get("https://ekyc.chili.mu:9443/otp/verifyOtpAgent/" + "230" + data.Custmsisdn + "/" + data.otp,
            {
                headers: { 'Content-Type': 'application/json; charset=utf-8' }
            }).then((res) => {
                // console.log("result is ",res.data.status)
                if (res.data.status = "SUCCESSFUL") {
                    isOtpSuccess(true);
                    setshowUpload(true)
                    isOtpFailed(false);
                    localStorage.setItem("CustomerNumber", data.Custmsisdn);
                    localStorage.setItem("NewUser","no")

                    if(localStorage.getItem('CustomerNumber').length==8){
                      window.location.replace("/categories")
                    }
                    else
                        alert("Please login again")
                    //updateCustomerMsisdn(data.Custmsisdn, 'no');
                }
            }).catch((error) => {
                isOtpFailed(true);
                console.log(error);
            });
    }
    return(
            <div className="Auth-container" style={{textAlign:'center'}}>

            {!isValidCust && (
                <div style={{ marginTop: -20, marginLeft: 30 }}>
                    <Alert severity="error">Invalid CHiLi Number.</Alert>
                </div>
            )}

                {otpFailed==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The OTP for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}
                {otpSuccess==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">Verification Successful. Please proceed </Alert>
                    </div>
                )}
                
                {otpSent==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">OTP is sent to your provided mobile number.</Alert>
                    </div>
                )}
                {otpSent==false && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The MSISDN for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}

                <h5 style={{ color: '#f049ac' }}> Customer Authentication </h5>
                <h6 style={{ color: 'Gray' }}> Please login with OTP </h6>
                <div>
                        <TextField                             
                             name="MSISDN"
                             type="tel"
                             id="outlined-required"
                             label={<div style={{color:'black'}}>MSISDN</div>}
                             variant="outlined"
                             inputProps={{maxLength:8}}
                             onChange={(e)=>{setCustmsisdn(e.target.value)
                             localStorage.setItem('CustomerNumber',e.target.value)}}
                        />
                    <br></br>
                    <br></br>
                    {
                        otpbox == false?
                        <div style={{textAlign:'center'}}>
                        <button  className="button_id"style={{ fontSize: 16,width: 120, padding:10}} onClick={checkforverification}>Send OTP</button>
                        <button  className="button_id"style={{ fontSize: 16,width: 120,  padding:10}} onClick={()=>{
                            isOtpSent()
                        isOtpFailed()}}><Link to="/" className="link1">Back</Link></button>
                        </div>
                         : 
                         <div>
                            <TextField
                            
                            name="OTP"
                            type="password"
                            id="outlined-required"
                            label={<div style={{color:'black'}}>OTP</div>}
                            variant="outlined"
                            inputProps={{maxLength:4}}
                            onChange={(e)=>{Setotp(e.target.value)}}

                            />
                            <br></br>
                            <br></br>

                            <button  className="button_id"style={{ fontSize: 16,width: 120 ,padding:10}} onClick={sendOTP}>Resend OTP</button>  
                            <button  className="button_id"style={{ fontSize: 16,width: 120, padding:10 }} onClick={verifyOTP}>Verify</button> 
                            <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}} onClick={()=>{
                                showotpbox(false)
                                isOtpFailed()
                                isOtpSuccess(false)
                                // isOtpSent(false)
                            }}>Back</button>
                            <br></br> 
                            {
                                showUpload== true?
                                <div>
                                    <br></br> <br></br> <br></br>
                                <button  className="button_id"style={{ fontSize: 16,width: 120, padding:10}}>
                                    <Link className="link1" to="/categories"> Next Page  </Link> </button>
                                </div>
                                :
                                <div>
                                   
                                </div>
                            }
                          </div>
                          
                    } 
                    <br></br>
                    <div style={{fontSize:12, marginTop:55}}>  
                        <label><strong>For eKYC please keep handy: </strong></label><br></br>
                        <label><strong>For CITIZEN(Mauritian): </strong> NID & Address Proof(Utility Bill/Bank Statement/Other)</label><br></br>
                        <label><strong>For Non-Citizen(Resident): </strong> Passport, Work/Occupation Permit, Address Proof(Company letter/Other)</label><br></br>
                        <label><strong>For Tourist: </strong> Passport, VISA, Address Proof(Accomodation/Hotel Booking/Other)</label>

                        
                    </div>
                    </div>
            </div>
    )
}