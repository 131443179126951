import react,{ useState } from "react"
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import './Terms.css'
import './style.scss';
import Bottomstrip from "./Bottomstrip";

export default function Terms(){

    return(
        <div className="Auth-container" >
            <div>
            <h3 style={{textAlign:'center',margin:5}}>Privacy Policy for Mahanagar Telephone (Mauritius) Limited</h3>
            
            <p>At Mahanagar Telephone (Mauritius) Limited, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Mahanagar Telephone (Mauritius) Limited and how we use it.</p>

            <p> 
                If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.

            </p>
                    

            <p>
                This Privacy Policy, together with the terms and conditions available in telecom App outline the App’s privacy 
                practices regarding the collection, processing, use, retention and safety of your information through the App and
                 the services offered thereupon.

                 </p>
                 <p>
                    Please note the download and use of the App are voluntary. The App is free of charge. 
                </p>

            <label style={{textAlign:'start'}}> ACCEPTANCE</label>

            <p>
                By using the App or registering or signing up for the App and submitting personal information to the App it will be deemed that you accept the terms of this Privacy Policy.
            </p>
            <p>
            Registration or usage of the App implies that you have consented to the processing of your information and agreed to the terms of this Privacy Policy now and as amended by us.
            </p>
            <p>
                This Privacy Policy is an electronic record, generated by a computer system and does not require any physical or digital signatures.

            </p>
            <label style={{textAlign:'start'}}> COMPLIANCE WITH DATA PROTECTION LAWS</label>
            <p>
            MTML is committed to ensure a high level of data protection for its customers, prospects, users of its products, services, websites, and mobile apps.
            <br></br>
            We are registered as Data Controller with the Data Protection Office and regulated by the Data Protection Act 2017. We are also required to comply with the General Data Protection Regulation (GDPR) in cases where services are being provided to EU residents, and in the European Community.

            </p>
            <p>
            We process all your data in compliance with the Data Protection Act 2017:
            <br></br>
            Log Files
            Mahanagar Telephone (Mauritius) Limited follows a standard procedure of using log files. 
            These files log visitors when they use app. The information collected by log files include internet protocol (IP) 
            addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the
             number of clicks. These are not linked to any information that is personally identifiable. The purpose of the
              information is for analyzing trends, administering the app, tracking users' movement on the app, and gathering
               demographic information.<br></br>

            (a)	Your Personal Data is processed lawfully, fairly, and in a transparent manner.<br></br>

            (b)	Your Personal Data is collected for specific, explicit and legitimate purposes.<br></br>

            (c)	Your Personal Data is accurate, kept up-to-date and every reasonable step is taken to ensure that personal data 
            that are inaccurate are rectified without delay.

            </p>

             <label style={{textAlign:'start'}}> WHAT INFORMATION IS GATHERED AND HOW IS IT PROCESSED?</label> 
                <p>
                    The following is the manner in which we collect, use, share and retain personal information: 
                    <br></br>
                </p>
                <label style={{textAlign:'start'}}>Collection</label>
                    <p>
                    You agree that the App may collect such personal information, whenever relevant, to help providing you with 
                    information and to complete any activity/transaction or provide any product or services you have requested or
                     authorized. You also consent to the collection of certain personal information in the course of you applying 
                     for the products and/or services.
                    </p>
                    <label style={{textAlign:'start'}}>Use</label>
                    <p>
                    The App seeks your personal information either (i) to validate and process your request for the services of the
                     App or information; or (ii) to improve the quality of the App; or (iii) to assist you to determine which 
                     services best meet your needs; or (iv) to facilitate our internal business operations, including the fulfilment
                      of any legal and regulatory requirements; or (v) to provide you with recommendation about services you may be 
                      interested in, based on your use of the App; or (vi) to provide you with marketing communications and
                       advertising that the App believes may be of interest of you(where you provided your consent. As the case may 
                       be); or (vii) to facilitate your purchases; or (viii) to facilitate your usage of any information, 
                       entertainment, promotion and/or engagement services/facilities availed by you though the App; or (ix) to
                        facilitate your participation in various contests or programs conducted by us on the App.
                    </p>
                    <label style={{textAlign:'start'}}>Sharing</label>
                <p>
                We will strive to keep your personal information confidential and will place reasonable security controls to protect it. Your personal information will be kept confidential as required under data privacy applicable laws.
                We may share personal information with our affiliates when necessary to perform services on our behalf or on your behalf, to provide advertising and promotional services (where you consented. As the case may be), to provide search results and links (including paid listings and links), to process credit card payments, to provide customer service etc.
                Only aggregated, anonymized data may be transmitted to external services to help us improve the App and our service. We will not share your information with any third parties except in the ways that are described in this Privacy Policy.
                        <br></br>
                        We may share data collected:<br></br>

                        (i)	as required by law, such as to comply with legal process;<br></br>

                        (ii)	when we believe in good faith that disclosure is necessary to protect our rights, 
                        protect your safety or the safety of others, investigate fraud, or respond to a government request;<br></br>

                        (iii)	with our trusted services providers who work on our behalf, do not have an independent use of the 
                        information we disclose to them, and have agreed to adhere to the rules set forth in this privacy policy.<br></br> <br></br>

                        RETENTION AND DATA SECURITY
                        <br></br>
                        <br></br>
                        We will retain the data only for a limited duration necessary to fulfil the purposes outlined herein unless a longer retention period is required or permitted by law and only for the purposes defined above. Once the purpose is achieved, all personal information is deleted in a safe and secure mode.

                        <br></br>
                        <br></br>
                        We have put in place appropriate technical and organizational measures in order to ensure a reasonably high level of security in the processing of your personal data.
                </p>
                <label style={{textAlign:'start'}}>WHAT ARE YOUR OPT-OUT OPTIONS?</label>
                <p>
                You can stop all collection of information by the App easily by uninstalling the App. If you have opted to get
                 notifications and you wish to stop receiving push notifications, you can change the settings on your mobile device
                  at any time.
                  <br></br>
                  <br></br>
                  Our Advertising Partners
                  <br></br>
                    Some of advertisers in our app may use cookies and web beacons. Our advertising partners are listed below. 
                    Each of our advertising partners has their own Privacy Policy for their policies on user data.
                    <br></br>
                    <br></br>

                    Privacy Policies
                    <br></br>

                    You may consult this list to find the Privacy Policy for each of the advertising partners of Mahanagar Telephone
                     (Mauritius) Limited.
                        <br></br>
                        <br></br>
                        
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Beacons that are used in their respective advertisements and links that appear on Mahanagar Telephone (Mauritius) Limited. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on this app or other apps or websites. Note that Mahanagar Telephone (Mauritius) Limited has no access to or control over these cookies that are used by third-party advertisers.
                        <br></br>
                        <br></br>
                        Third Party Privacy Policies<br></br>
                    Mahanagar Telephone (Mauritius) Limited's Privacy Policy does not apply to other advertisers or websites.
                     Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more 
                     detailed information. It may include their practices and instructions about how to opt-out of certain options.
                     <br></br>
                     <br></br>
                     Children's Information<br></br>
                    Another part of our priority is adding protection for children while using the internet. We encourage parents 
                    and guardians to observe, participate in, and/or monitor and guide their online activity. Mahanagar Telephone
                     (Mauritius) Limited does not knowingly collect any Personal Identifiable Information from children under the 
                     age of 13. If you think that your child provided this kind of information on our App, we strongly encourage 
                     you to contact us immediately and we will do our best efforts to promptly remove such information from our 
                     records.
                        <br></br> <br></br>

                        Online Privacy Policy Only<br></br>
                        This Privacy Policy applies only to our online activities and is valid for visitors to our App with regards
                         to the information that they shared and/or collect in Mahanagar Telephone (Mauritius) Limited. This policy
                          is not applicable to any information collected offline or via channels other than this app. Our Privacy 
                          Policy was created with the help of the App Privacy Policy Generator from App-Privacy-Policy.com
                        <br></br> <br></br>
                        Terms and Conditions
                        <br></br>
                        These terms and conditions outline the rules and regulations for the use of Mahanagar Telephone (Mauritius)
                         Limited. By using this app we assume you accept these terms and conditions. Do not continue to use
                          Mahanagar Telephone (Mauritius) Limited if you do not agree to take all of the terms and conditions stated
                           on this page.
                        <br></br>
                        <br></br>
                        The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and
                         all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to 
                         the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
                          "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, 
                          acceptance and consideration of payment necessary to undertake the process of our assistance to the Client
                           in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of
                            provision of the Company’s stated services, in accordance with and subject to, prevailing law of Mauritius.
                             Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or 
                             they, are taken as interchangeable and therefore as referring to same. Our Terms and Conditions were 
                             created with the help of the App Terms and Conditions Generator from App-Privacy-Policy.com
                             <br></br>
                             <br></br>
                             License<br></br>
                            Unless otherwise stated, Mahanagar Telephone (Mauritius) Limited and/or its licensors own the
                             intellectual property rights for all material on Mahanagar Telephone (Mauritius) Limited. All 
                             intellectual property rights are reserved. You may access this from Mahanagar Telephone (Mauritius)
                              Limited for your own personal use subjected to restrictions set in these terms and conditions.
                              <br></br>
                              You must not:<br></br>

                                Republish material from Mahanagar Telephone (Mauritius) Limited<br></br>

                                Sell, rent or sub-license material from Mahanagar Telephone (Mauritius) Limited<br></br>

                                Reproduce, duplicate or copy material from Mahanagar Telephone (Mauritius) Limited Redistribute content from Mahanagar Telephone (Mauritius) Limited<br></br>
                                This Agreement shall begin on the date hereof.
                                <br></br>
                                <br></br>
                                Mahanagar Telephone (Mauritius) Limited does not filter, edit, publish or review Comments prior to
                                 their presence on the website. Comments do not reflect the views and opinions of Mahanagar
                                  Telephone (Mauritius) Limited, its agents and/or affiliates. Comments reflect the views and 
                                  opinions of the person who post their views and opinions. To the extent permitted by applicable 
                                  laws, Mahanagar Telephone (Mauritius) Limited shall not be liable for the Comments or for any
                                   liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of
                                    and/or appearance of the Comments on this website.
                                    <br></br>
                                    <br></br>
                                Mahanagar Telephone (Mauritius) Limited reserves the right to monitor all Comments and to remove any
                                 Comments which can be considered inappropriate, offensive or causes breach of these Terms and 
                                 Conditions.
                                <br></br>
                                You warrant and represent that:<br></br>

                            •	You are entitled to post the Comments on our App and have all necessary licenses and consents to do
                             so;<br></br>
                            •	The Comments do not invade any intellectual property right, including without limitation copyright,
                             patent or trademark of any third party;<br></br>
                            •	The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful 
                            material which is an invasion of privacy<br></br>
                            •	The Comments will not be used to solicit or promote business or custom or present commercial 
                            activities or unlawful activity.<br></br>
                            You hereby grant Mahanagar Telephone (Mauritius) Limited a non-exclusive license to use, reproduce, edit
                             and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or 
                             media.
                             <br></br>
                             Hyperlinking to our App
                             <br></br>

                            The following organizations may link to our App without prior written approval: 
                            <br></br>


                            •	Government agencies; <br></br>

                            •	Search engines;<br></br>

                            •	News organizations;<br></br>

                            •	Online directory distributors may link to our App in the same manner as they hyperlink to the Websites of other listed 
                            businesses; and <br></br>

                            •	System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising 
                            groups which may not hyperlink to our Web site. <br></br><br></br>
                            These organizations may link to our home page, to publications or to other App information so long as 
                            the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or
                             approval of the linking party and its products and/or services; and (c) fits within the context of the
                              linking party's site.
                                <br></br>
                                <br></br>
                                We may consider and approve other link requests from the following types of organizations:
                                <br></br>

                                •	commonly-known consumer and/or business information sources;<br></br>
                                •	dot.com community sites;<br></br>
                                •	associations or other groups representing charities;<br></br>
                                •	online directory distributors;<br></br>
                                •	internet portals;<br></br>
                                •	accounting, law and consulting firms; and<br></br>
                                •	educational institutions and trade associations.<br></br>
                                <br></br>
                                We will approve link requests from these organizations if we decide that:
                                 (a) the link would not make us look unfavorably to ourselves or to our accredited businesses;
                                  (b) the organization does not have any negative records with us; (c) the benefit to us from the 
                                  visibility of the hyperlink compensates the absence of Mahanagar Telephone (Mauritius) Limited; 
                                  and (d) the link is in the context of general resource information.
                                  <br></br><br></br>
                                  These organizations may link to our App so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and
                                    (c)	fits within the context of the linking party's site.
                                    <br></br><br></br>

                                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our App, you must inform us by 
                                    sending an e-mail to Mahanagar Telephone (Mauritius) Limited. Please include your name, your organization name, contact information
                                    as well as the URL of your site, a list of any URLs from which you intend to link to our App, and a list of the URLs on our site to
                                    which you would like to link. Wait 2-3 weeks for a response.
                                    <br></br><br></br>
                                    Approved organizations may hyperlink to our App as follows: 
                                    <br></br>
                                    •	By use of our corporate name; or<br></br>
                                    •	By use of the uniform resource locator being linked to; or<br></br>
                                    •	By use of any other description of our App being linked to that makes sense within the
                                     context and format of content on the linking party's site.<br></br>

                                     No use of Mahanagar Telephone (Mauritius) Limited's logo or other artwork will be allowed for
                                      linking absent a trademark license agreement.<br></br><br></br>
                                     <label style={{textAlign:'start'}}> iFrames</label><br></br>
                                    Without prior approval and written permission, you may not create frames around our Webpages
                                     that alter in any way the visual presentation or appearance of our App.<br></br>

                                     We may share data collected:<br></br>

                                        (i)	as required by law, such as to comply with legal process;<br></br>

                                        (ii)	when we believe in good faith that disclosure is necessary to protect our rights,
                                         protect your safety or the safety of others, investigate fraud, or respond to a government
                                          request;<br></br>

                                        (iii)	with our trusted services providers who work on our behalf, do not have an 
                                        independent use of the information we disclose to them, and have agreed to adhere to the 
                                        rules set forth in this privacy policy.<br></br>

                </p>
                <label style={{textAlign:'start'}}> RETENTION AND DATA SECURITY</label>
                <p>

                We will retain the data only for a limited duration necessary to fulfil the purposes outlined herein unless a longer
                 retention period is required or permitted by law and only for the purposes defined above. Once the purpose is 
                 achieved, all personal information is deleted in a safe and secure mode.<br></br> <br></br>


                We have put in place appropriate technical and organizational measures in order to ensure a reasonably high level of
                 security in the processing of your personal data.


                </p>
                <label style={{textAlign:'start'}}> WHAT ARE YOUR OPT-OUT OPTIONS?</label>
                        <p>

                        You can stop all collection of information by the App easily by uninstalling the App. If you have opted to get notifications and you wish to stop receiving push notifications, you can change the settings on your mobile device at any time.

                        Content Liability<br></br>
                        We shall not be hold responsible for any content that appears on your App. You agree to protect and defend us against all claims
                        that is rising on our App. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or 
                        which infringes, otherwise violates, or advocates the infringement or other violation of, any third party 
                        rights.<br></br><br></br>

                        Reservation of Rights<br></br>
                        We reserve the right to request that you remove all links or any particular link to our App. You approve to
                         immediately remove all links to our App upon request. We also reserve the right to amen these terms and 
                         conditions and it's linking policy at any time. By continuously linking to our App, you agree to be bound 
                         to and follow these linking terms and conditions.<br></br><br></br>

                         Removal of links from our App<br></br>
                         If you find any link on our App that is offensive for any reason, you are free to contact and inform us any
                          moment. We will consider requests to remove links but we are not obligated to or so or to respond to you 
                          directly.<br></br> <br></br>

                         We do not ensure that the information on this website is correct, we do not warrant its completeness or
                          accuracy; nor do we promise to ensure that the website remains available or that the material on the
                           website is kept up to date.<br></br> <br></br>
                           Disclaimer<br></br>
                           To the maximum extent permitted by applicable law, we exclude all representations, warranties and 
                           conditions relating to our App and the use of this website. Nothing in this disclaimer will:<br></br>

                            •	limit or exclude our or your liability for death or personal injury;<br></br>
                            •	limit or exclude our or your liability for fraud or fraudulent misrepresentation;<br></br>
                            •	limit any of our or your liabilities in any way that is not permitted under applicable law; or<br></br>
                            •	exclude any of our or your liabilities that may not be excluded under applicable law.<br></br><br></br>
                            The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: 
                            (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer,
                             including liabilities arising in contract, in tort and for breach of statutory duty.
                             <br></br>
                             As long as the website and the information and services on the website are provided free of charge, we
                              will not be liable for any loss or damage of any nature.
                              <br></br><br></br>
                              

                            We are doing our best to prepare the content of this app. However, Mahanagar Telephone (Mauritius)
                             Limited cannot warranty the expressions and suggestions of the contents, as well as its accuracy. In
                              addition, to the extent permitted by the law, Mahanagar Telephone (Mauritius) Limited shall not be 
                              responsible for any losses and/or damages due to the usage of the information on our app. Our 
                              Disclaimer was generated with the help of the App Disclaimer Generator from App-Privacy-Policy.com
                              <br></br>
                              By using our app, you hereby consent to our disclaimer and agree to its terms.
                              <br></br><br></br>
                              The App shall not be responsible for any interactions between the user and third-party websites. 
                              Users are encouraged to review the privacy policies and terms of third-party websites independently. 
                              This App holds no liability for actions taken solely based on the provision of links to third-party
                               websites on our platform.
                               <br></br><br></br>

                            Any links contained in our app may lead to external sites are provided for convenience only. Any 
                            information or statements that appeared in these sites or app are not sponsored, endorsed, or otherwise
                             approved by Mahanagar Telephone (Mauritius) Limited. For these external sites, Mahanagar Telephone 
                             (Mauritius) Limited cannot be held liable for the availability of, or the content located on or through
                              it. Plus, any losses or damages occurred from using these contents or the internet generally.
                              <br></br><br></br>
                        </p>
                        <label style={{textAlign:"center"}}>YOUR RIGHTS</label>
                        <p>
                        a.	As an App user you have rights under the data protection law. Although we aim to give you a gist of your
                         fundamental rights, it is imperative that you do not rely strictly on our explanation but should read 
                         official guidance from the relevant authorities to be fully informed.<br></br>

                        b.	Your basic rights under data protection law are the following:<br></br>
                        (i)	the right to access;<br></br>

                        (ii)	the right to rectification;<br></br>

                        (iii)	the right to erasure;<br></br>

                        (iv)	the right to restrict processing;<br></br>

                        (v)	the right to object to processing;<br></br>

                        (vi)	the right to data portability;<br></br>

                        (vii)	the right to complain to a supervisory authority; and<br></br>

                        (viii)	the right to withdraw consent.<br></br>

                        c.	You have the right to:<br></br>

                        (i)	Confirm whether the personal data may be processed;<br></br>

                        (ii)	Know where we process it;<br></br>

                        (iii)	Access your personal data;<br></br>

                        (iv)	Access additional information including the details and purposes of the processing;<br></br>

                        (v)	Access to categories of personal data;<br></br>

                        (vi)	Be aware of the recipients of the personal data; and<br></br>

                        (vii)	Obtain copy of your personal data provided that the rights and freedoms of others are not affected.<br></br>

                        d.	You have the right to rectify any personal data about you, which proves to be inaccurate, and remaining
                         mindful of the purposes of the processing, you may complete any incomplete personal data about you.<br></br>
                        e.	Circumstances wherein you have an immediate right to the erasure of your personal data include the 
                        following:<br></br>
                        (i)	Your personal data are no longer necessary for the purpose for which they were collected or 
                        processed;<br></br>
                        (ii)	You withdraw your consent;<br></br>
                        (iii)	You do not consent to the processing under certain rules of data protection law; or<br></br>
                        (iv)	The data was unlawfully obtained given that the processing was for marketing purposes and you never
                         consent to same.<br></br>
                        f.	Please note that there are exceptions to the right of erasure, which include circumstances when
                         processing is necessary to abide by a legal obligation.<br></br>
                        g.	You retain the right to restrict the processing of your personal data in the following
                         circumstances:<br></br>
                        (i)	You dispute the accuracy of your personal data;<br></br>
                        (ii)	Unlawful processing but you object to erasure;<br></br>
                        (iii)	Your data is no longer required for the purposes of processing but you need it in relation to legal
                         claims<br></br>
                        h.	You retain the right to object to the processing of your personal data on the basis of your specific
                         situation but subject to the processing being necessary:<br></br>
                        (i)	For the performance of a function carried out in the public interest; or<br></br>
                        (ii)	If acts are carried out by an official authority; or<br></br>
                        (iii)	For the purposes of our legitimate interests or that of another third party.<br></br>
                        Should you choose to object, the processing of all your personal information shall cease absent the 
                        existence of compelling legitimate grounds which unequivocally demonstrate the need to process such 
                        information despite the latter procedure overriding your rights, freedom or if the processing is required to
                         establish or defend any legal claims.<br></br>
                        i.	You retain the right to object to the processing of your personal data including profiling, for marketing
                         purposes and we shall accordingly forestall or halt the procedure.<br></br>
                        j.	You retain the right to object to the processing of your personal data for the purposes of historical
                         research or for statistics on the basis of your specific situation save and except if the processing is
                          necessary in the public interests.<br></br>
                        Subject to the legal basis of the processing of your personal data being:<br></br>
                        (i)	consent or it is necessary for the performance of a contract; and<br></br>
                        (ii)	such processing is carried out by automated means.<br></br>
                        k.	You retain the right to obtain your personal data from us in a structured, commonly used and 
                        machine- readable format provided it does not infringe the rights and freedoms of others.<br></br>
                        l.	You retain the legal right to lodge a complaint with the authority responsible for data protection if you
                         are of the view that the processing of your personal information violates data protection laws.<br></br>
                        m.	You retain the right to withdraw your consent to the processing of your personal information at any time 
                        provided that consent was the legal basis prior to initiating that process. Subsequent withdrawal of consent
                         shall neither affect nor question the legality of the processing of your personal data prior to withdrawal.
                        In order to claim these rights, please get in touch via the contact information below. This also applies, if
                         you wish to object to the processing of your data in accordance with this data privacy policy in full or in
                          relation to individual measures.<br></br><br></br>
                        Compliances<br></br>

                        Complies to Data Privacy as per The Data Protection Act 2017 of Mauritius & Information and Communication
                         Technologies (Registration of SIM) Regulations 2023.<br></br><br></br>

                        Contact us<br></br>
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
                        mtml@mtmltd.net

                                                </p>
                                                
            
            </div>
            <div style={{textAlign:"center"}}>
                         <Link to="/success"><button  className="button_id"style={{fontSize: 16, width: 120,}}>Back </button></Link> 
            </div>         
        </div>
    )
}