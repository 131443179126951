import React, { Component } from "react";
import react,{ useState } from "react"
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
    return <MuiAlert elevation={6}
        variant="filled" {...props} />;
}

export default function Agent(){

    const[agentmsisdn,Setagentmsisdn] = useState("")
    const [mpin,Setmpin] = useState("")
    
    const[showUpload,setshowUpload] = useState(false)
    const[otpSuccess, isOtpSuccess] = useState();
    const[otpFailed, isOtpFailed] = useState();
    const[otpSent, isOtpSent] = useState();

    const { classes } = ""
    
    function loginhandle(e){
        e.preventDefault();
    }
    
    function checkAgentType(msisdn){
        
        const req = Axios.get("https://ekyc.chili.mu:9443/api/agentCheck/230" + msisdn,
            {
                headers: { 'Content-Type': 'application/json' }
            }).then((res) => {
                
                if (res.data.httpCode == 200) {
                    localStorage.setItem("agentType", res.data.data);
                    localStorage.setItem("AgentNumber", msisdn);
                    console.log(res.data.data)
                    //window.location.replace("/choosecustomer")
                    
                }
                
            }).catch((error) => {
                console.log(error);
                alert('Agent/Dealer not registered.');
                //window.location.replace("/agentotp")
            });
            

    }

    function verify(){
        let data = {agentmsisdn,mpin}
        checkAgentType(agentmsisdn);

         const req =  Axios.get("https://ekyc.chili.mu:9443/otp/verifyMpin/230"+data.agentmsisdn+"/"+data.mpin,
                    {
                     headers: {'Content-Type':'application/json'}
                }).then((res) => {
                 console.log("result is ",res.data.data)
                 console.log(data);
                 window.location.replace("/choosecustomer")
                setshowUpload(true)
                localStorage.setItem("AgentNumber",agentmsisdn)

                 isOtpSent(true)

                    }).catch((error)=>{
                        console.log(error);
                isOtpSent(false)
                     });
    }

   
    return(
        <div className="Auth-container" style={{textAlign:'center'}}>

                {otpFailed==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The OTP for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}
                {otpSuccess==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">Verification Successful. Please proceed </Alert>
                    </div>
                )}
                
                {otpSent==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">OTP is sent to your provided mobile number.</Alert>
                    </div>
                )}
                {otpSent==false && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The MSISDN/MPIN for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}

            <h5 style={{ color: '#f049ac' }}>
                    Agent Authentication
                </h5>
            <br></br>
            <TextField
                            name="MSISDN"
                            type="tel"
                            id="outlined-required"
                            label={<div style={{color:'black'}}>MSISDN</div>}
                            variant="outlined"
                            inputProps={{maxLength:8}}
                            onChange={(e)=>{Setagentmsisdn(e.target.value)
                            localStorage.setItem("AgentNumber",e.target.value)}}
                        />
                    <br></br>
                    <br></br>
                    <TextField
                            name="M-PIN"
                            type="password"
                            id="outlined-required"
                            label={<div style={{color:'black'}}>M-PIN</div>}
                            variant="outlined"
                            inputProps={{maxLength:4}}
                            onChange={(e)=>{Setmpin(e.target.value)
                                localStorage.setItem("agentpin",e.target.value)}}
                        />
                    <br></br>
                    <br></br>
                 <Link  to="/setpin">Set/Reset MPIN?</Link> 
                 <br></br>
                    
                    <br></br>
                    { mpin.trim().length==4 && mpin!=null && agentmsisdn.trim().length==8 ?
                    <button className="button" style={{ fontSize: 16,width: 120, padding:10}} onClick={verify} >Verify</button>
                     :null
                    }
                        {
                            showUpload?
                            <button className="button" style={{ fontSize: 16,width: 120, padding:10}}>
                                <Link className="link1" to="/choosecustomer">Proceed</Link></button>
                            :null
                        }
                        <button className="button" style={{ fontSize: 16,width: 120, padding:10}} >
                    <Link className="link1" to="/welcomeagent"><span>Back</span></Link>
                    </button>
        </div>
    )
}