import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
// import ReactDOM from 'react-dom';
import Fullscreen from 'react-full-screen';
import addressim from '../address.png';
import * as Icon from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';


import { Root, Preview, GlobalStyle } from '../styles';
import './style1.css';
import { Camera } from '../camera_bk';
import './style.scss';
import axios from 'axios';
import Bottomstrip from './Bottomstrip';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import MuiAlert from "@material-ui/lab/Alert";
import { TextField } from 'material-ui';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        '&:before': {
            borderColor: 'Gray',
        },
        color: 'Blue',
    },
    icon: {
        fill: 'Gray',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6}
        variant="filled" {...props} />;
}
 

export default function Selfie(props) {
    const addressprooftype = localStorage.getItem('adressdocumentType')
    localStorage.setItem('IconType','edit');
    const agentNumber = localStorage.getItem("AgentNumber")
    const [validResponse, setValidResponse] = useState(true);
    const [isBkCameraOpen, setIsBkCameraOpen] = useState(false);
    const [cardImage, setCardImage] = useState();
    // const token = localStorage.getItem('token');
    const isselfiecomplete = localStorage.getItem("SelfieCompleted")
    const UserType = localStorage.getItem("userType")
    const[showbtn,setshowbtn]=useState(false)
    const[errmsg,seterrmsg]=useState()
    let tokenStr=localStorage.getItem('Token')
    if(localStorage.getItem('contingency')!=null &&localStorage.getItem('contingency')=='true' ){
        tokenStr="T"+tokenStr;
    }

    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);
    const classes = useStyles();
    
    let loggedin = true;
    if (tokenStr == null) {
        loggedin = false;
    }
    //localStorage.removeItem('kyc-photo');
    const docwhich = localStorage.getItem('whichdoc');
    if (docwhich) {
        console.log(docwhich);
    }

    //localStorage.removeItem('whichid');
    //localStorage.removeItem('imgId1');
    const doc_f = localStorage.getItem('imgId7');
    let photoclk = false;
    
    const [isFull, setIsFull] = useState(false);
    //  const who1 = JSON.parse(localStorage.getItem('data'));
    const [whichId, setWhichId] = useState(docwhich ? docwhich : '');
    function handleChange_close() {
        setIsBkCameraOpen(false);
        setCardImage(undefined);
        setIsFull(false);
        upload(doc_f);
    }

    
    function handleChange(event) {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState((prevState) => ({
            details: {
                ...prevState.details,
                [nam]: val,
            },
        }));
        console.log('cc', val);
        if (nam == "firstName") {
            localStorage.setItem('fn', val)
        }
        if (nam == "lastName") {
            localStorage.setItem('ln', val)
        }
        if (nam == "gender") {
            localStorage.setItem('gender', val)
        }
        if (nam == "cc") {
            localStorage.setItem('countryCode', val)
        }
        if (nam == "nid") {
            localStorage.setItem('id', val)
        }
        if (nam == "type") {
            localStorage.setItem('type', val)
        }
        if (nam == "puid") {
            localStorage.setItem('puid', val)
        }

        console.log(nam, val);

        // this.setState(prevState=>{
        //     let details = Object.assign({}, prevState.details);
        //     nam = val;
        // })

    };

    // setWhichId(docwhich);

    function upload() {
        // doc_f = localStorage.getItem('imgId1');
        localStorage.setItem('doc_f',doc_f);
        const formData = new FormData();
        formData.append('image', doc_f);
       // formData.append('docname', localStorage.getItem('adressdocumentType'));
        formData.append('token', tokenStr);
        photoclk = true;
        const request = axios.post('https://ekyc.chili.mu:9443/api/processUID', formData, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(function (response) {
                setshowbtn(true)
                setValidResponse(true);

                //console.log('successfully uploaded', doc_f);
                photoclk = false;
                //localStorage.setItem('address',response.data.data.address);
                
                //console.log("Addressresponse",localStorage.getItem('address'));
            })
            .catch(function (error){
                // setshowbtn(true)

               // onCloseAlert('error','Please try again later or visit the nearest CHiLi KYC center.');
                if (error.response) {
                setValidResponse(false);
                seterrmsg(error.response.data.data)
                    console.log("error msg= ",error.response.data.data);
                   const x =  error.response.status;
                    console.log(error.response.data);
                //     if(x==404){
                //         alert(error.response.data.data)
                //   }
                  

                }
            }
            
            );
            
    }
    console.log(isselfiecomplete)

    useEffect(() => {
        localStorage.setItem('whichdoc', whichId);
    });
    if (loggedin === false) {
        localStorage.clear();
        return <Redirect to="/"></Redirect>;
    } else {
        // console.log(whichId);
        return (
            <Fragment>
                {
                    agentNumber==null || agentNumber.trim().length==0?
                    null
                    :<div  style={{marginTop:-50,textAlign:'center', marginLeft:75}}>
                        <label><strong>Agent Number:{agentNumber}</strong></label>
                        <Link to="/logout" style={{textAlign:'end',marginLeft:20,color:'black'}}>Logout</Link>
                        <br></br>
                    </div>
                }

                {!validResponse && (
                    <div style={{ marginTop: 0, marginLeft: 90 }}>
                        {/* <Alert severity="error">You are already verified with another Token number Kindly visit your nearest
                         Chili Store to complete your E-KYC.</Alert> */}
                         <Alert severity="error">{errmsg}</Alert>
                    </div>
                )}
                
                

                <div style={{ marginTop: 0, marginLeft: 90 }}>
                    <span>
                        <Icon.Info id="Popover1" type="button" />
                        Tips
                    </span>
                    <Popover
                        placement="bottom"
                        isOpen={popoverOpen}
                        target="Popover1"
                        toggle={toggle}
                    >
                        <PopoverHeader>UID/Work Permit Tips</PopoverHeader>
                        <PopoverBody>
                        1. Place your ID in the given overlay.<br></br> 2.
                            Don't worry about the extra space, it would be cropped
                            <br></br>3. Turn off the auto-rotate feature of your
                            mobile
                            <br></br> 4. You can always take it back.
                        </PopoverBody>
                    </Popover>
                </div>
              
                <div style={{ textAlign: 'center' }}>
                <h5
                    style={{
                        textAlign: 'center',
                        color: '#f049ac',
                        marginLeft: 60,
                        marginTop: 20,
                    }}
                >
                    
                    Upload  UID 
                </h5>
                <h6 style={{ color: 'Gray', marginLeft: 90 }}>
                Please upload UID for KYC verification
                </h6>
                <div
                    style={{
                        fontSize: 12,
                        color: 'Gray',
                        textAlign: 'center',
                        marginLeft: 85,
                    }}
                >
                    
                   
                </div>
            </div>
           
        
                
                <div
                    className="whichid selfie_page"
                    style={{  marginTop: 30 }}
                >
                    
                </div>
               
                {addressprooftype == 'consent'?
                
                <div className='Auth-container' style={{textAlign:'center'}}>
                  <Link to='/consent'>  <button className='button'>Go to consent upload</button></Link>
                </div>
                :<div>
                    <div className="selfie_page">
                    { (
                        <div className="id_logo">
                            <img
                                className="idcard"
                                src={addressim}
                                alt="address"
                                style={{
                                    marginTop: 55,
                                    marginLeft: 15,
                                }}
                            ></img>
                        </div>
                    )}
                    <div className="">
                        <Root>
                            <Fullscreen
                                enabled={isFull}
                                onChange={(isFull) => setIsFull(isFull)}
                            >
                                {isBkCameraOpen && isFull && (
                                    <Camera
                                        onCapture={(blob) => setCardImage(blob)}
                                        onClear={() => setCardImage(undefined)}
                                        changekaru={handleChange_close}
                                        
                                    />
                                    
                                )}
                                
                                {cardImage && (
                                    <div>
                                        <h2>Preview</h2>
                                        <Preview
                                            src={
                                                cardImage &&
                                                URL.createObjectURL(cardImage)

                                            }
                                        />
                                    </div>
                                )}
                            </Fullscreen>
                           

                       
                        <br></br>
                        
                            <div className="camera-btn1">
                                <button
                                    className="button_id"
                                    onClick={() => {
                                        setIsBkCameraOpen(true);
                                        setIsFull(true);
                                        localStorage.setItem("from","Addressproof")
                                    }}
                                    style={{
                                        marginLeft: 55,
                                        marginTop: 55,
                                        fontSize: 16,
                                        width: 120,
                                    }}
                                >
                                    Scan
                                </button>
                                
                                {  (
                                    
                                    <Link
                                        to="/uiddetails"
                                        onClick={
                                          doc_f
                                                ? null
                                                : (e) => e.preventDefault()
                                        }
                                    >
                                        <button
                                            className="button_id"
                                            style={{
                                                fontSize: 16,
                                                width: 120,
                                            }}
                                            disabled={
                                                  doc_f
                                                    ? false
                                                    : true
                                            }
                                        >
                                            Proceed
                                        </button>
                                    </Link>
                                ) }
                                

                                {/* <button
                                        className="button"
                                        onClick={() => {
                                            setIsBkCameraOpen(false);
                                            setCardImage(undefined);
                                            setIsFull(false);
                                        }}
                                    >
                                        Close Camera
                                    </button> */}

                                  {/*  <Link
                                        to="/selfie"
                                       
                                    >
                                        <button
                                            className="button_id"
                                            style={{
                                                fontSize: 16,
                                                width: 120,
                                            }}
                                          
                                        >
                                            Skip
                                        </button>
                                        </Link> */} 
                            </div>
                        </Root>
                        <GlobalStyle />
                    </div>
                    <br></br><br></br>
                    
                    <div className="header" style={{marginLeft:100}}>
                        <img
                            className="classid_f"
                            src={`data:image/jpeg;base64,${doc_f}`}
                            height="150"
                            width="240"
                            alt="Front"
                        />
                        <br></br>
                        <br></br>

                      
                    </div>
                </div>
                    </div>}

                
                
                


                
                
                
                
                        
                
                <Bottomstrip/>
            </Fragment>
        );


    }




}
