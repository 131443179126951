import react,{ useState } from "react"
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Bottomstrip from "./Bottomstrip";
import './custome.css'
import Axios from "axios";
import thankyou from '../thankyou.png'
import MuiAlert from "@material-ui/lab/Alert";



function Alert(props) {
    return <MuiAlert elevation={6}
        variant="filled" {...props} />;
}
export default function Conclude(){
    const[customernumber,setcustomernumber]=useState(false)
    const[msisdn,Setmsisdn] = useState()
    const[otp,Setotp] = useState()
    const[otpbox,showotpbox] = useState(false)
    const[showsubmit,setshowsubmit] = useState(false)
    const mailid=localStorage.getItem('email')
    const [count,setcount]=useState(0);
    const[otpSuccess, isOtpSuccess] = useState(false);
    const[otpFailed, isOtpFailed] = useState(false);
    const[otpsentsuccessful,isotpsentsuccessfull]=useState(false)
    const[otpsentfailed,isotpsentfalied]=useState(false)
    const newCustomer=localStorage.getItem("NewUser")
    const agentNumber = localStorage.getItem('AgentNumber') 
    let tokenStr=localStorage.getItem('Token')
    if(localStorage.getItem('contingency')!=null &&localStorage.getItem('contingency')=='true' ){
        tokenStr="T"+tokenStr;
    }
    const token = tokenStr

    function sendsms(){
        isOtpSuccess(false)
        let data = {msisdn}
        let txt = `Congratulations your Re-Verification request is accepted and is subject to confirmation of documents submitted. Your Token ID is ${token}`;
        if(newCustomer=="yes"){
            txt = `Congratulations your SIM request is accepted and is subject to confirmation of documents submitted. Your Token ID is ${token}`;
        }
        //let txt = `Dear Customer, Congratulations! Welcome to the family of CHILI. Your reference for eKYC Corelation ID is- ${token} for ${data.msisdn} Enjoy the CHiLi services.`


        // const req =  Axios.get("https://ekyc.chili.mu:9443/otp/verifyOtp/"+"230"+data.msisdn+"/"+data.otp,
        // let text = 'Dear Customer, Congratulations! Welcome to the family of CHILI. Your reference for eKYC is'+token+' Enjoy the CHiLi services.\n'+'Best regards\n'+'MTML Chilli';

        const req = Axios.get("https://ekyc.chili.mu:9443/ekyc/v1/sms?msisdn="+"230"+data.msisdn+"&from=2306668&text="+txt+"&configId=2&locale=en",
            {
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }).then((res) => {
            console.log("result is ",res.data.status)
            setcount(count+1);
        
           }).catch((error)=>{
                        console.log(error);
                    });  
    }

    function sendEmail(){
        let token=tokenStr
        
        const req = Axios.get("https://ekyc.chili.mu:9443/email?email="+mailid+"&token="+token+"&status=success&newuser="+newCustomer,
        // const req = Axios.get("https://ekyc.chili.mu:9443/email?email=ajay@wpitservices.com&token=56445667asdafssfddfg&status=success",

        {
            headers:{'Content-Type':'application/json;charset=utf-8'}
        }).then((res)=>{
            console.log("email response is= ",res);
        }).catch((error)=>{
            console.log(error)
        });
    }

    function sendOTP(){
        let data = {msisdn}

        const req =  Axios.get("https://ekyc.chili.mu:9443/otp/sendOtpAgent/230"+ data.msisdn + "/en",
           {
            headers: {'Content-Type':'application/json'}
        }).then((res) => {
            //  console.log("result is ",res.data.data)
            // console.log(data);
        isotpsentsuccessfull(true);
            showotpbox(true);   

            }).catch((error)=>{
                console.log(error);
            showotpbox(false);   
            isotpsentsuccessfull(false)
            });
    }
    
    
    function verifyOTP(){
          //on success response
        isotpsentsuccessfull(false);

        let data = {msisdn,otp}
        const req =  Axios.get("https://ekyc.chili.mu:9443/otp/verifyOtpAgent/230" + data.msisdn + "/" + data.otp,
        {
            headers: {'Content-Type':'application/json; charset=utf-8'}
        }).then((res) => {
            // console.log("result is ",res.data.status)
            if(res.data.status="SUCCESSFUL"){
                isOtpSuccess(true);
                setshowsubmit(true)
                updateMsisdn(msisdn, 'no');

            }
           }).catch((error)=>{
                isOtpFailed(true);
                        console.log(error);
                    });  
    }

    function updateMsisdn(msisdn, isNew){
        const axios = require('axios');
            const qs = require('qs');

            let msisdnStr='230'+msisdn;
            let token=localStorage.getItem('Token');
            let data = qs.stringify({
                'token': tokenStr,
                'msisdn': msisdnStr,
                'newCustomer': isNew,
                'iccid': '***********'
            });
    
    
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://ekyc.chili.mu:9443/api/msisdnUpdate',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: data
            };
    
            axios.request(config)
                .then((response) => {
                   console.log(response.data);
                   alert('Number Added')
    
                })
                .catch((error) => {
                    
                    console.error('error updating msisdn');
                });
    
    }

    // function updateMsisdn(msisdn, isnew){
    //     const formData = new FormData();
    //     formData.append('token', tokenStr);
    //     formData.append('msisdn', "230"+msisdn);
    //     formData.append('newCustomer', isnew);
    //     formData.append('iccid', '***********');
        

    //    const request = Axios.post('https://ekyc.chili.mu:9443/api/msisdnUpdate',formData, {
    //         headers: {
    //             "Content-Type": "application/json; charset=utf-8",
    //             "Access-Control-Allow-Headers": "*",
    //             "Access-Control-Allow-Origin": "*",
    //             "Access-Control-Allow-Methods": "*"
    //         }
    //     }).then((res)=>{
    //         console.log(res.data);
           

    //     }).catch((error)=>{
    //         console.error('error updating msisdn');
    //     });
    // }

    return (
            <div className="Auth-container" style={{textAlign:'center'}}>

                {otpFailed==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The OTP for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}
                {otpSuccess==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">Verification Successful. Please proceed </Alert>
                    </div>
                )}
                
                {otpsentsuccessful==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="success">OTP is sent to your provided mobile number.</Alert>
                    </div>
                )}
                {otpsentfailed==true && (
                    <div style={{ marginTop: -20, marginLeft: 30 }}>
                        <Alert severity="error">The MSISDN for this user is incorrect. Please check and retry..</Alert>
                    </div>
                )}

                {
                    customernumber ?
                    <div>
                                <h4
                    style={{
                        textAlign: 'center',
                        color: '#f049ac',
                        marginLeft: 20,
                        marginTop: 20,
                    }}>Add Another Number </h4>
                        <h6>Dear Customer Please add your Additional MSISDN</h6>
                            <TextField
                            name="msisdn"
                            type="tel"
                            id="outlined-required"
                            label={<div>MSISDN</div>}
                            variant="outlined"
                            inputProps={{maxLength:8}}
                            onChange={(e)=>Setmsisdn(e.target.value)}
                            />
                            
                            
                             
                            {otpbox ?
                            <div>
                                <br></br>
                                <TextField
                            name="otp"
                            type="tel"
                            id="outlined-required"
                            label={<div>OTP</div>}
                            variant="outlined"
                            inputProps={{maxLength:4}}
                            onChange={(e)=>Setotp(e.target.value)}
                            />
                            <br></br>
                            <br></br>
                            <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                            onClick={()=>{
                                verifyOTP()
                            }}> Verify OTP</button>
                            </div>
                            :
                                <div>
                                    { newCustomer=='yes'? <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                            onClick={()=>{ 
                                            updateMsisdn(msisdn, 'yes');
                                            window.location.reload()
                            }}> Add Number</button>
                        
                        :<button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                        onClick={()=>{
                            sendOTP()
                        }}> Send OTP</button>}

                                </div>
                            
                            // <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                            // onClick={()=>{
                            //     sendOTP()
                            // }}> Send OTP</button>
                            }



                            <br></br>
                            <br></br>
                            

                            
                            
                            {showsubmit ?
                                <button className="button_id"style={{ fontSize: 16,width: 120, padding:10}}
                                onClick={()=>{
                                    setcustomernumber(false)
                                    sendsms()
                                    showotpbox(false)
                                    setshowsubmit(false)
                                }}> Submit</button>
                            :null}
                                

                            </div>
                    :<div>
                        <img
                        className=""
                        src={thankyou}
                        style={{
                            height: 200,
                            marginTop: 100,
                        }}
                        alt="logoo"></img>
                        <h4> Thank you! Your data has been saved </h4>
                        <label>Your E-KYC Token/Tracking Id is - <strong>{tokenStr}</strong> Please visit Our nearest Chili Store</label>
                        <br></br>
                        { count === 3 ?
                        null
                        :<div>
                            { newCustomer ==="yes"? <label className="text-primary"   onClick={()=>setcustomernumber(true)}>Want to add more number? click here</label>
                                :<label className="text-primary"   onClick={()=>setcustomernumber(true)}>Want to add more number? click here</label>
                            }
                        </div>          
                       }
                        <br></br>
                       { agentNumber!=null?
                       <button   className="button_id"style={{ fontSize: 16,width: 120, padding:10}}>
                       <Link className="link1" onClick={()=>{//sendEmail()
                                   }} to="/choosecustomer"> Login Again?  </Link>
                       </button>
                    :null}
                        

                            <br></br>
                        <button   className="button_id"style={{ fontSize: 16,width: 120, padding:10}}>
                        <Link className="link1" onClick={()=>{//sendEmail()
                                    }} to="/logout"> Exit  </Link>
                        </button>
                    </div>
                }

            </div>
    )
}